body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mbtYellow {
  color: rgb(213, 187, 69);
}

.mbtBlue{
color: rgb(0, 45, 100);
}

.bxs{
  border-radius: 10px;
background: #ffffff;
box-shadow:  8px 8px 16px #d9d9d9,
             -8px -8px 16px #ffffff;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

.no-pad{
  padding: 0px !important;
}

.for{
  position: relative;
  top: 150px !important;
}

.carousel-indicators [data-bs-target]{
  background-color: gray;
}
.carousel-indicators {
  bottom: -25px;
}

.top, .bottom{
  height: 50vh;
  width: 100%;
  cursor: pointer;
  color: whitesmoke;
  
}

.top{
  background: black url(assets/shutterstock_781802926.jpg) center/100% auto no-repeat;
  color: black;
}
.bottom{
  background: black url(assets/shutterstock_1081518080.jpg)  center/100% auto no-repeat;
  color: whitesmoke;
}
@media screen and (min-width:768px){
  .top, .bottom{
      height: 100vh;
      width: 590px;
    }
}